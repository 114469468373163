const browserApis = [
  {
    name: "IntersectionObserver",
    get supported() {
      return "IntersectionObserver" in window
    },
  },
  {
    name: "ResizeObserver",
    get supported() {
      return "ResizeObserver" in window
    },
  },
  {
    name: "CSS Layers",
    get supported() {
      return "CSSLayerBlockRule" in window
    },
  },
]

export function areBrowserApisSupported() {
  return browserApis.every((api) => api.supported)
}

export function verifyBrowserApisSupported() {
  if (!areBrowserApisSupported()) {
    window.location.href = "/unsupported-browser.html"
  }
}
